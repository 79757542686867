<template>
  <BaseListPage locale-section="pages.inscriptions" route="inscription">
    <InscriptionsTree :selected-signum1="$route.params.signum1" />
  </BaseListPage>
</template>

<script>
export default {
  name: "InscriptionsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    InscriptionsTree: () => import("./InscriptionsTree")
  }
};
</script>
